export const getLocalStorageItem = (key: string) => {
    let item = null

    try {
        if (typeof window !== 'undefined') {
            item = window.localStorage.getItem(key)
        }
    } catch (e) {
        console.log(e)
    }

    return item
}

export const setLocalStorageItem = (key: string, value: string) => {
    try {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, value)
        }
    } catch (e) {
        console.log(e)
    }
}

export const deleteLocalStorageItem = (key: string) => {
    try {
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem(key)
        }
    } catch (e) {
        console.log(e)
    }
}
