'use client'
import Script from 'next/script'
import { useCookie } from 'web/src/lib/context/cookie-context'
import React, { ReactNode } from 'react'

type ScriptList = {
    id: string
    script?: string
    src?: string
    loadingStrategy?: 'afterInteractive' | 'worker' | 'beforeInteractive' | 'lazyOnload'
}

const WebsiteScripts = () => {
    const { cookiePreferences } = useCookie()
    const scripts: ScriptList[] = []

    if (cookiePreferences?.statistics) {
        if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
            scripts.push({
                id: 'hotjar-script',
                loadingStrategy: 'lazyOnload',
                script: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            })
        }
    }

    if (cookiePreferences?.marketing) {
        if (process.env.NEXT_PUBLIC_KLAVIYO_API_KEY) {
            scripts.push({
                id: 'klaivyo-on-site',
                src: `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_API_KEY}`,
            })
        }
        // Add Facebook
        if (process.env.NEXT_PUBLIC_FACEBOOK_API_KEY) {
            scripts.push({
                id: 'facebook-script',
                script:
                    '!function(f,b,e,v,n,t,s)\n' +
                    '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
                    'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
                    "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
                    'n.queue=[];t=b.createElement(e);t.async=!0;\n' +
                    't.src=v;s=b.getElementsByTagName(e)[0];\n' +
                    "s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
                    "'https://connect.facebook.net/en_US/fbevents.js');\n" +
                    `fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_API_KEY});\n` +
                    "fbq('track', 'PageView');",
            })
        }
    }

    return (
        <>
            {scripts?.map(({ loadingStrategy, id, script, src }) => {
                if (src) {
                    return (
                        <Script
                            strategy={loadingStrategy || 'afterInteractive'}
                            type="text/javascript"
                            key={id}
                            id={id}
                            src={src}
                        />
                    )
                } else if (script) {
                    return (
                        <Script
                            strategy={loadingStrategy || 'afterInteractive'}
                            type="text/javascript"
                            key={id}
                            id={id}
                            dangerouslySetInnerHTML={{ __html: script }}
                        />
                    )
                } else {
                    return null
                }
            })}
        </>
    )
}

export default WebsiteScripts
