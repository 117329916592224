'use client'
import * as React from 'react'
import Dialog from '../Dialog'
import { useEffect, useRef } from 'react'
import * as Switch from '@radix-ui/react-switch'
import Button from '../Button'
import ArrowNextIcon from '../../icons/arrowNextIcon'
import { useCookie } from 'web/src/lib/context/cookie-context'

const CookieNotification = () => {
    const ref = useRef<HTMLDivElement>(null)
    const {
        dialogOpen,
        setDialogOpen,
        showNotice,
        setShowNotice,
        cookiePreferences,
        updateCookiePreferences,
        acceptCookies,
        denyCookies,
    } = useCookie()

    useEffect(() => {
        if (ref.current) {
            if (showNotice) {
                ref.current.classList.remove('hidden')
            } else {
                ref.current.classList.add('hidden')
            }
        }
    }, [ref, showNotice])

    return (
        <>
            <div
                ref={ref}
                className="px-[20px] py-[25px] bg-white z-[1100] fixed bottom-[20px] left-[20px] border-color-[rgb(138, 138, 138, 0.2) border-[1px] rounded-10 w-[350px] hidden"
            >
                <h2 className="mb=[10px] text-[20px]">Cookies van onze Chefs?</h2>
                <p className="text-[14px]">Accepteer onze cookies voor een optimale klantervaring.</p>
                <div className="mt-[28px] flex flex-col gap-[10px]">
                    <Button
                        type="button"
                        aria-label={'Open cookie instellingen'}
                        onClick={() => {
                            setDialogOpen(true)
                            setShowNotice(false)
                        }}
                        className="btn btn-outlined"
                    >
                        Instellingen
                    </Button>
                    <Button
                        aria-label={'Accepteer alle cookies'}
                        onClick={acceptCookies}
                        rightIcon={<ArrowNextIcon />}
                        className="min-w-0 w-full btn-primary"
                    >
                        Accepteer cookies
                    </Button>
                </div>
            </div>
            <Dialog
                buttonCopy={'Voorkeuren opslaan'}
                title={'Cookie instellingen'}
                content={
                    <div className={'flex flex-col gap-5 lg:max-w-[600px] '}>
                        <div className="border-[1px] border-grey/20 p-5 rounded-10 opacity-40">
                            <div className="flex justify-between items-center mb-[12px]">
                                <p className="font-bold text-[16px]">Noodzakelijk</p>
                                <Switch.Root
                                    checked
                                    className="p-1 data-[state=checked]:bg-[#005E55] rounded-[60px] border-[1px] border-grey/20 w-[44px]"
                                >
                                    <Switch.Thumb className="data-[state=checked]:translate-x-[18px] translate-x-[0px] data-[state=checked]:bg-white transition-transform duration-200  block  rounded-full w-[16px] h-[16px] bg-[#8A8A8A]" />
                                </Switch.Root>
                            </div>
                            <p className="text-[12px] leading-[14px]">
                                Noodzakelijke cookies helpen een website bruikbaarder te maken, door basisfuncties als
                                paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te maken.
                                Zonder deze cookies kan de website niet naar behoren werken.
                            </p>
                        </div>
                        {/*<div className="border-[1px] border-grey/20 p-5 rounded-10">*/}
                        {/*    <div className="flex justify-between items-center mb-[12px]">*/}
                        {/*        <p className="font-bold text-[16px]">Voorkeuren</p>*/}
                        {/*        <Switch.Root*/}
                        {/*            checked={cookiePreferences?.preferences}*/}
                        {/*            onCheckedChange={value => updateCookiePreferences({ preferences: value })}*/}
                        {/*            className="p-1 data-[state=checked]:bg-[#005E55] rounded-[60px] border-[1px] border-grey/20 w-[44px]"*/}
                        {/*        >*/}
                        {/*            <Switch.Thumb className="data-[state=checked]:translate-x-[18px] translate-x-[0px] data-[state=checked]:bg-white transition-transform duration-200  block  rounded-full w-[16px] h-[16px] bg-[#8A8A8A]" />*/}
                        {/*        </Switch.Root>*/}
                        {/*    </div>*/}
                        {/*    <p className="text-[12px] leading-[14px]">*/}
                        {/*        Voorkeurscookies zorgen ervoor dat een website informatie kan onthouden die van invloed*/}
                        {/*        is op het gedrag en de vormgeving van de website, zoals de taal van uw voorkeur of de*/}
                        {/*        regio waar u woont.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        <div className="border-[1px] border-grey/20 p-5 rounded-10">
                            <div className="flex justify-between items-center mb-[12px]">
                                <p className="font-bold text-[16px]">Statistieken</p>
                                <Switch.Root
                                    checked={cookiePreferences?.statistics}
                                    onCheckedChange={value => updateCookiePreferences({ statistics: value })}
                                    className="p-1 data-[state=checked]:bg-[#005E55] rounded-[60px] border-[1px] border-grey/20 w-[44px]"
                                >
                                    <Switch.Thumb className="data-[state=checked]:translate-x-[18px] translate-x-[0px] data-[state=checked]:bg-white transition-transform duration-200  block  rounded-full w-[16px] h-[16px] bg-[#8A8A8A]" />
                                </Switch.Root>
                            </div>
                            <p className="text-[12px] leading-[14px]">
                                Statistische cookies helpen eigenaren van websites begrijpen hoe bezoekers hun website
                                gebruiken, door anoniem gegevens te verzamelen en te rapporteren.
                            </p>
                        </div>
                        <div className="border-[1px] border-grey/20 p-5 rounded-10">
                            <div className="flex justify-between items-center mb-[12px]">
                                <p className="font-bold text-[16px]">Marketing</p>
                                <Switch.Root
                                    checked={cookiePreferences?.marketing}
                                    onCheckedChange={value => updateCookiePreferences({ marketing: value })}
                                    className="p-1 data-[state=checked]:bg-[#005E55] rounded-[60px] border-[1px] border-grey/20 w-[44px]"
                                >
                                    <Switch.Thumb className="data-[state=checked]:translate-x-[18px] translate-x-[0px] data-[state=checked]:bg-white transition-transform duration-200  block  rounded-full w-[16px] h-[16px] bg-[#8A8A8A]" />
                                </Switch.Root>
                            </div>
                            <p className="text-[12px] leading-[14px]">
                                Marketingcookies worden gebruikt om bezoekers te volgen wanneer ze verschillende
                                websites bezoeken. Hun doel is advertenties weergeven die zijn toegesneden op en
                                relevant zijn voor de individuele gebruiker. Deze advertenties worden zo waardevoller
                                voor uitgevers en externe adverteerders.
                            </p>
                        </div>
                    </div>
                }
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false)
                }}
                onButtonClick={() => {
                    if (!cookiePreferences) {
                        denyCookies()
                    }

                    setDialogOpen(false)
                }}
            />
        </>
    )
}

export default CookieNotification
