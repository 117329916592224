'use client'

import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage'
import { gtagEnabled } from 'utils/tracking'
import { useParams } from 'next/navigation'

type CookiePreferencesProps = {
    //preferences: boolean
    statistics: boolean
    marketing: boolean
}

interface CookieContext {
    dialogOpen: boolean
    setDialogOpen: (value: boolean) => void
    showNotice: boolean
    setShowNotice: (value: boolean) => void
    cookiePreferences: CookiePreferencesProps | null
    updateCookiePreferences: (value: Partial<CookiePreferencesProps>) => void
    acceptCookies: () => void
    denyCookies: () => void
}

const CookieContext = createContext<CookieContext | null>(null)

const COOKIE_PREFERENCE_KEY = 'CookieConsentSettings'

export const CookieProvider = ({ children }: PropsWithChildren) => {
    const params = useParams()
    const cookiePreferencesStorage = useMemo(() => getLocalStorageItem(COOKIE_PREFERENCE_KEY), [])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [showNotice, setShowNotice] = useState(!cookiePreferencesStorage)
    const [cookiePreferences, setCookiePreferences] = useState<CookiePreferencesProps | null>(
        cookiePreferencesStorage
            ? (JSON.parse(cookiePreferencesStorage) as CookiePreferencesProps)
            : {
                  statistics: false,
                  marketing: false,
              },
    )

    const storeCookiePreferences = (preferences: CookiePreferencesProps) => {
        setLocalStorageItem(COOKIE_PREFERENCE_KEY, JSON.stringify(preferences))
        setCookiePreferences(preferences)
    }

    const bulkUpdateCookies = useCallback((value: boolean) => {
        storeCookiePreferences({
            statistics: value,
            marketing: value,
        })

        setShowNotice(false)
        setDialogOpen(false)
    }, [])

    const denyCookies = useCallback(() => bulkUpdateCookies(false), [bulkUpdateCookies])

    const acceptCookies = useCallback(() => bulkUpdateCookies(true), [bulkUpdateCookies])

    const updateCookiePreferences = useCallback(
        ({ /*preferences0,*/ marketing, statistics }: Partial<CookiePreferencesProps>) => {
            storeCookiePreferences({
                //preferences: typeof preferences !== 'undefined' ? preferences : !!cookiePreferences?.preferences,
                statistics: typeof statistics !== 'undefined' ? statistics : !!cookiePreferences?.statistics,
                marketing: typeof marketing !== 'undefined' ? marketing : !!cookiePreferences?.marketing,
            })
        },
        [/*cookiePreferences?.marketing, cookiePreferences?.preferences,*/ cookiePreferences?.statistics],
    )

    const memoedValue = useMemo(
        () => ({
            dialogOpen,
            setDialogOpen,
            showNotice,
            setShowNotice,
            cookiePreferences,
            updateCookiePreferences,
            acceptCookies,
            denyCookies,
        }),
        [dialogOpen, showNotice, cookiePreferences, updateCookiePreferences, acceptCookies, denyCookies],
    )

    useEffect(() => {
        if (gtagEnabled()) {
            gtag('consent', 'update', {
                ad_user_data: /*cookiePreferences?.marketing === true ? 'granted' :*/ 'denied',
                ad_personalization: /*cookiePreferences?.marketing === true ? 'granted' :*/ 'denied',
                ad_storage: /*cookiePreferences?.marketing === true ? 'granted' :*/ 'denied',
                analytics_storage: cookiePreferences?.statistics === true ? 'granted' : 'denied',
            })
        }
    }, [cookiePreferences])

    useEffect(() => {
        if (window.location.hash === '#cookie-settings') {
            setDialogOpen(true)
        }
    }, [params])

    return <CookieContext.Provider value={memoedValue}>{children}</CookieContext.Provider>
}

export const useCookie = () => {
    const context = useContext(CookieContext)

    if (context === null) {
        throw new Error('useCookie must be used within a CookieProvider')
    }

    return context
}
