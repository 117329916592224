import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/apps/web/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieProvider"] */ "/app/apps/web/src/lib/context/cookie-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/files/TanNimbus/TAN-NIMBUS.woff2\",\"variable\":\"--font-tan-nimbus\",\"display\":\"swap\"}],\"variableName\":\"tanNimbus\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/files/FilsonPro/FilsonPro-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/files/FilsonPro/FilsonPro-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/files/FilsonPro/FilsonPro-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-filson-pro\",\"display\":\"swap\"}],\"variableName\":\"filsonPro\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/files/Chaney/chaney-regular.woff2\",\"variable\":\"--font-chaney\",\"display\":\"swap\"}],\"variableName\":\"chaney\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/Calendar/react-datepicker.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/CookieNotification/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/WebsiteScripts/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/styles/global.css");
