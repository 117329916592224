'use client'

import { MEDUSA_BACKEND_URL } from '@lib/config'
import { CartProvider, MedusaProvider } from 'medusa-react'
import { Filter, StoreProvider } from '@lib/context/store-context'
import { AccountProvider } from '@lib/context/account-context'

import React, { ReactNode } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { VariantList } from '../types/medusa'
import { Country } from '@medusajs/medusa'

export function Providers({
    allVariants,
    mealTypeId,
    availableCountries,
    filters,
    children,
}: {
    filters: Filter[]
    availableCountries: Country[]
    allVariants: VariantList
    mealTypeId: string
    children: ReactNode
}) {
    return (
        <MedusaProvider
            baseUrl={MEDUSA_BACKEND_URL}
            queryClientProviderProps={{
                client: new QueryClient({
                    defaultOptions: {
                        queries: {
                            refetchOnWindowFocus: false,
                            staleTime: 1000 * 60 * 60 * 24,
                            retry: 1,
                        },
                    },
                }),
            }}
        >
            <CartProvider>
                <StoreProvider
                    allAvailableCountries={availableCountries}
                    allFilters={filters}
                    allVariants={allVariants}
                    mealTypeId={mealTypeId}
                >
                    <AccountProvider>{children}</AccountProvider>
                </StoreProvider>
            </CartProvider>
        </MedusaProvider>
    )
}
